<template>
    <div>
		<b-modal ref="modalCourrierFeuilleSaillie" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("horse.print_infos_horse") }}
				</template>
			</template>

			<div class="col-12">
				<div class="form-group">
					<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
					<e-select
						v-model="template"
						id="model_id"
						track-by="model_label"
						label="model_label"
						:placeholder="labelTitleTemplate"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:options="templates"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>

					<label for="modeltype_label">{{ $t('monte.creneaux_print') }} *</label>
					<e-select
						v-model="creneaux_selected"
						id="id"
						track-by="label"
						label="label"
						:options="creneaux"
						:searchable="true"
						:show-labels="false"
						multiple
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>

				<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("global.print") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Model from "@/mixins/Model.js"
import PlanningMonte from '@/mixins/PlanningMonte.js'

export default {
	name: "modalCourrierFeuilleSaillie",
	mixins: [Model, PlanningMonte],
	data () {
		return {
			templates: [],
			template: null,
			processing: false,
			season_id: null,
			type: null,
			lieu_id: null,
			date: null,
			creneaux: null,

			creneaux_selected: [],

			deselectLabel: this.getTrad("global.press_enter_to_remove"),
			selectedLabel: this.getTrad("global.selected_label"),
			enter_to_select: this.getTrad("global.press_enter_to_select"),

			labelTitleFormat: this.getTrad("horse.search_format"),
			labelTitleTemplate: this.getTrad("horse.search_doc")
		}
	},
	methods: {
		async init_component() {
			this.processing = false
			this.template = null
			this.templates = await this.loadAllModel(0, ['creneaux_saillie'])

			if(this.templates.length == 1) {
				this.template = this.templates[0]
			}
		},
		openModal(params) {
			this.season_id = params.season_id
			this.type = params.type
			this.lieu_id = params.lieu_id
			this.date = params.date

			let creneaux = []
			if(this.type == 'main') {
				for(let i in params.infos) {
					for(let j in params.infos[i]) {
						if(params.infos[i][j].length > 0) {
							for(let k in params.infos[i][j]) {
								let creneau = params.infos[i][j][k]

								let schedule = params.schedule.find(sch => sch.schedule_id == j)

								let label = '(' + schedule.schedule_start + ' - ' + schedule.schedule_end + ') ' + creneau.stallion.horse_nom + ' / '

								label += creneau.mare ? creneau.mare.horse_nom : '?'
								if(creneau.tiers) {
									label += ' (' + creneau.tiers.tiers_rs + ')'
								}
								else if(creneau.contact) {
									label += ' (' + creneau.contact.contact_firstname + ' ' + creneau.contact.contact_lastname + ')'
								}

								creneaux.push({
									id: creneau.id,
									label: label
								})
							}
						}
					}
				}
			}
			else {
				for(let i in params.schedule) {
					if(params.schedule[i].infos.length > 0) {
						for(let j in params.schedule[i].infos) {
							let creneau = params.schedule[i].infos[j]
							let schedule = params.schedule[i].schedule
							let stallion = params.schedule[i].stallion

							let label = '(' + schedule.schedule_start + ' - ' + schedule.schedule_end + ') ' + stallion.horse_nom + ' / '

							label += creneau.mare ? creneau.mare.horse_nom : '?'
							if(creneau.tiers) {
								label += ' (' + creneau.tiers.tiers_rs + ')'
							}
							else if(creneau.contact) {
								label += ' (' + creneau.contact.contact_firstname + ' ' + creneau.contact.contact_lastname + ')'
							}

							creneaux.push({
								id: creneau.id,
								label: label
							})
						}
					}
				}
			}
			this.creneaux = creneaux
			this.creneaux_selected = creneaux

			this.$refs.modalCourrierFeuilleSaillie.show()
		},
		async checkForm() {
			if(this.template) {
				this.processing = true
				let creneaux_ids = this.creneaux_selected.map(cr => cr.id)
				this.printDayFeuilleSaillie(this.season_id, this.type, this.lieu_id, this.date, this.template.model_id, creneaux_ids)
				.then(() => {
					this.processing = false
				})
			}
			else {
				this.failureToast()
			}
		}
	}
}
</script>